import axiosMaker from '../../axios'

const API_REQUEST_SENT = 'API_REQUEST_SENT'
const API_REQUEST_FAILURE = 'API_REQUEST_FAILURE'
const SAVE_GRADE_SUCCESS = 'SAVE_GRADE_SUCCESS'

export const apiRequestSent = () => {
    return {
        type : API_REQUEST_SENT,
    }
}

export const apiRequestFailure = (error) => {
    return {
        type : API_REQUEST_FAILURE,
        payload : error
    }
}


export const saveGradeSuccess = (message) => {
    return{
        type : SAVE_GRADE_SUCCESS,
        payload : message
    }
}



export const saveGrade = (grade) => {
    return async (dispatch) => {
        dispatch(apiRequestSent())

        await axiosMaker.post('submission/teacher/update', grade)
        .then(
            dispatch(saveGradeSuccess("Grade saved."))
        )
        .catch(error => {
            dispatch(apiRequestFailure(error))
        })
    }
}

const initialState = {
    isLoading : false,
    dataSaved : false,
    error : null,
    messaage : null
}

const saveGradeReducer = (state = initialState, action) => {
    switch (action.type) {
        case API_REQUEST_SENT:
            return{
                ...state,
                dataSaved: false,
                isLoading : true
            }

        case SAVE_GRADE_SUCCESS:
            return {
                isLoading : false,
                dataSaved : true,
                error : null,
                message : action.payload
            }

        case API_REQUEST_FAILURE:
            return {
                ...state,
                error : action.payload
            }
            
        default: return state
    }
}

export default saveGradeReducer
