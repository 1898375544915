import React from 'react';
import './Tasks.css';
import axios from 'axios';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { LOADDATA } from '../constants/actionTypes';
import { connect } from 'react-redux';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'


const localizer = momentLocalizer(moment)

const mapStateToProps = state => ({ ...state.item });

const mapDispatchToProps = dispatch => ({

    onLoad: (data) => {
        dispatch({ type: LOADDATA, payload: data })
    }


});

class Tasks extends Component {
    constructor() {
        console.log('tasks')
        super();
    }
    state = {
        data: []
    }


    loadData = async (e) => {
        console.log("Test");
        const response = axios.get("https://engine.ed2tech.net/api/notes"
        ).then((response) => {
            if(response.data && response.data.data){
                let tasksList = response.data.data.map(task => {
                    return {
                    name: task.title,
                    id: task.id,
                    title: task.title,
                    allDay: true,
                    start: new Date(task.created_at),
                    end: new Date(task.created_at)
                    }
                });
                this.setState({ data: tasksList })
            }
            
            console.log(response.data)
            
            console.log(this.state.data);

        });

    }
    viewDetails = (id) => {
        console.log(id);
    }

    componentDidMount() {
         this.loadData()
         
        // this.setState({data: [{
        //     id: 0,
        //     title: 'All Day Event very long title',
        //     allDay: true,
        //     start: new Date(2020, 11, 12),
        //     end: new Date(2020, 11, 12),
        //   }]})
    }

    renderData = () => {
        console.log("data: ", this.state.data)
        return this.state.data.map((item) => {
            const { id, name, description, due_date } = item //destructuring
            return (
                <tr key={id}>
                    <td>{name}</td>
                    <td>{description}</td>
                    <td>{due_date}</td>
                    {/* <td><Link to={{ pathname: '/item-details/' + id }}>
                        Details
                    </Link> */}
                    {/* </td> */}
                </tr>
            )
        })
    }
    render() {
        return (

            <Calendar
                localizer={localizer}
                events={this.state.data}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
            />

            // <div className="Ads">
            //     <table id="customers">
            //         <thead>
            //             <tr>
            //                 <th>Name</th>
            //                 <th>Description</th>
            //                 <th>Due Date</th>
            //             </tr>
            //         </thead>
            //         <tbody>
            //             {this.renderData()}
            //         </tbody>
            //     </table>
            // </div>



        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);