import React, { Component, useState } from 'react';
import styled from 'styled-components'
import { saveGrade } from './Modal.logic'
import { connect } from 'react-redux'

import {
    Background,
    ModalWrapper,
    CloseModalButton,
    Title,
    FormWrapper,
    ResultWrapper,
    Result,
    Label,
    Comment,
    CommentSection,
    GradeSection,
    Grade,
    SubmitButton
} from './Modal.style'

const Modal = (props) => {
    const [comments, setComments] = useState(props.selectedResult === undefined ? null : props.selectedResult.comments)
    const [grade, setGrade] = useState(props.selectedResult === undefined ? "" : props.selectedResult.grade)

    const _setGrade = (e) => {
        let value = e.target.value
        setGrade(prevState => value)
    }

    const _setComments = (e) => {
        let value = e.target.value
        setComments(prevState => value)
    }

    const _formSubmit = e => {
        e.preventDefault();
        props.saveGrade({ 
            grade: grade,
            accepted: 1,
            comments: comments,
            student_id: props.selectedResult.student_id,
            task_id: props.selectedResult.task_id
        })
        props.setShowModal(props.task, props.selectedResult)
    } 

    
    return  <Background>
                <ModalWrapper>
                    <Label>Task</Label>
                    <p>{ props.task.title }</p>
                    <Result>
                        <Label>Result</Label>
                        {/* <p>{ props.selectedResult.result } </p> */}

                        <iframe src="https://engine.ed2tech.net/uploads/test.benutzer3-16.html" width="100%" height="400"></iframe>
                    </Result>
                    <FormWrapper onSubmit={ _formSubmit }>
                        <CommentSection>
                            <Label>Comment</Label>
                            <Comment id="comment" rows = "10" onChange={ _setComments }>{ comments }</Comment>
                        </CommentSection>
                        <GradeSection>
                            <Label>Grade</Label>
                            <Grade id="grade" value={ grade } onChange={ _setGrade } />
                            <SubmitButton>Submit</SubmitButton>
                        </GradeSection>
                    </FormWrapper>
                    <CloseModalButton arial-label='Close modal' onClick={ () => props.setShowModal(props.task, props.selectedResult) } />
                </ModalWrapper>
            </Background>
}

const mapStateToProps = (state) => {
    return{
        isLoading : state.saveGrade.isLoading,
        dataSaved : state.saveGrade.dataSaved,
        message : state.saveGrade.message,
        error : state.saveGrade.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        saveGrade: (grade) => dispatch(saveGrade(grade))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Modal)

