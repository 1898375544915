import styled from 'styled-components'
import { MdClose } from 'react-icons/md'

export const Background =  styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
`

export const ModalWrapper = styled.div`
  width  : 800px;
  min-height : 500px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  position: relative;
  z-index: 10;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
`

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`
export const ResultWrapper = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex-grow: 1;
` 
export const Label = styled.label`
  font-size: 18px;
  display: block;
  margin-bottom: 5px;
  margin-top: 10px;
`
export const Title = styled.p`
  background: #1abc9c;
  padding: 15px;
  text-align: left;
  color: white;
  opacity: 1;
`

export const FormWrapper = styled.form`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-column-gap: 10px;
`

export const Result = styled.div`
  flex-grow: 1;
`

export const Comment = styled.textarea`
  background: #f2f2f2;
  border: none;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
`

export const CommentSection = styled.section`
  flex-grow: 4;
`

export const GradeSection = styled.section`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const Grade = styled.input`
  background: #f2f2f2;
  height: 100px;
  width: 100%;
  text-align: center;
  font-size: 26px;
`
export const SubmitButton =  styled.button`
  width: 100%;
  background: #1abc9c;
  border-radius: 5px;
  height: 40px;
  margin-top: auto;
  color: white;
`
