import React from 'react';
import './Error.css'
const MyError = (props) => {
    return (
        <div className="Error">
            <h1>Oops! Something went wrong!</h1>
        </div>
 
    )
};

export default MyError; 