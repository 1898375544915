
import auth from './reducers/auth';
import item from './reducers/item';
import submissionOverviewReducer from './TaskSubmissionOverview/TaskSubmissionOverview.logic'
import saveGradeReducer from './components/Modal/Modal.logic'
import { combineReducers } from 'redux';


export default combineReducers({
  auth : auth,
  item : item,
  submissionOverview : submissionOverviewReducer,
  saveGrade : saveGradeReducer
});
