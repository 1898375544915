import axios from 'axios'
import axiosMaker from '../axios'

const API_REQUEST_SENT = 'API_REQUEST_SENT'
const API_REQUEST_FAILURE = 'API_REQUEST_FAILURE'
const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS'
const SAVE_GRADE_SUCCESS = 'SAVE_GRADE_SUCCESS'

export const apiRequestSent = () => {
    return {
        type : API_REQUEST_SENT,
    }
}

export const apiRequestFailure = (error) => {
    return {
        type : API_REQUEST_FAILURE,
        payload : error
    }
}

export const fetchDataSuccess = (tasks, submittedTasks) => {
    return {
        type : FETCH_DATA_SUCCESS,
        payload : {
            tasks : tasks,
            submittedTasks : submittedTasks
        }
    }
}

export const saveGradeSuccess = (message) => {
    return{
        type : SAVE_GRADE_SUCCESS,
        payload : message
    }
}

export const fetchData = () => {
    console.log("hello i am here")
    return async (dispatch) => {

        dispatch(apiRequestSent())

        const dn = "cn=DE_HE_LKF_Fachoberschule__Sprache_LJ1_Spanisch_1,cn=Protecmedia Schule,cn=HE,ou=de,ou=groups,o=ed2tech,dc=ed2tech,dc=net"
        const fetchTasks = axiosMaker.post('submission/teacher/notes',{ group_dn: dn })
        const fetchSubmittedTasks = axiosMaker.post('submission/teacher/overview',{ group_dn: dn })

        await axios.all([fetchTasks, fetchSubmittedTasks])
        .then(
            axios.spread((...allData) => {
                const tasks = allData[0].data.data
                const submittedTasks = allData[1].data.data
                console.log(tasks)
                console.log(submittedTasks)
                dispatch(fetchDataSuccess(tasks, submittedTasks))
            })
        )
        .catch(error => {
            console.log(error)
            dispatch(apiRequestFailure(error))
        })
    }
}


const saveGrade = (gradeObject) => {
        // const unreadNotifications = _.filter(notifications, ['read', 0])
        // return unreadNotifications
}

const initialState = {
    isLoading : false,
    dataFetched : false,
    dataSaved : false,
    error : null,
    messaage : null,
    tasks : [],
    submittedTasks : []
}

const submissionOverviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case API_REQUEST_SENT:
            return{
                ...state,
                isLoading : true
            }
        
        case FETCH_DATA_SUCCESS:
            return{
                ...state,
                isLoading : false,
                dataFetched : true,
                error : null,
                tasks : action.payload.tasks,
                submittedTasks : action.payload.submittedTasks
            }

        case SAVE_GRADE_SUCCESS:
            return {
                isLoading : false,
                dataSaved : true,
                error : null,
                message : action.payload
            }

        case API_REQUEST_FAILURE:
            return {
                ...state,
                error : action.payload
            }
            
        default: return state
    }
}

export default submissionOverviewReducer
