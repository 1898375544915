import React from 'react';
import './AddTask.css';
import axios from 'axios';
import { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from "react-datepicker";
import moment from 'moment'

import "react-datepicker/dist/react-datepicker.css";

class AddTask extends Component {
    constructor() {
        super();
        this.validator = new SimpleReactValidator();
    }
    state = {
        item: {
            "name": "",
            "description": "",
            "dueDate": new Date()
        },
        selectedFile: []
    }
    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    addItem = async (e) => {

        debugger;
        const formatedDate = moment(this.state.item.dueDate).format('YYYY-MM-DD HH:mm:ss')
        console.log(this.state.item.dueDate)
        if (!this.state.selectedFile[0]) {
            return;
        }
       
        console.log()
        let taskData = new FormData();
        taskData.append('name', this.state.item.name)
        taskData.append('description', this.state.item.description)
        taskData.append('due_date', formatedDate)
        taskData.append('file_name', this.state.selectedFile[0].name)
        taskData.append('file_type', this.state.selectedFile[0].type)
        taskData.append('file_content', await this.toBase64(this.state.selectedFile[0]))
        if (this.validator.allValid()) {
            console.log(taskData)
            const response = axios.post(
                'https://engine.ed2tech.net/api/task/teacher/create',
                taskData
            ).then((response) => {
                debugger;
                console.log("Add Item Response")
                console.log(response.data);
                this.props.history.push('/tasks')

            }).catch(error => {
                console.log(error)
            });
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }

        // const postAxios = await postAxiosMaker()
        // postAxios.post('announcements', taskData)
        //     .then(async response => {
        //         if (response.data.message === "success") {
        //             dispatch(addNotificationSuccess("Notification Scheduled"))
        //         } else {
        //             dispatch(addNotificationFailure("Something went wrong"))
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error)
        //         dispatch(addNotificationFailure(error))
        //     })






    }

    componentDidMount() {

    }

    changeTaskName = (ev) => {
        this.setState({
            item: {
                ...this.state.item,
                name: ev.target.value
            }
        })
        console.log(this.state);
    }
    changeTaskDescription = (ev) => {
        this.setState({
            item: {
                ...this.state.item,
                description: ev.target.value
            }
        })
    }

    fileChangedHandler = (event) => {
        this.setState({ selectedFile: event.target.files });
        console.log(event.target.files)
    }
    handleDateSelect = (date) => {
        console.log(date)
        console.log('handle date select')
        this.setState({
            item: {
                ...this.state.item,
                dueDate: date
            }
        })
        
    }
    handleDateChange = (date) => {
        const formatedDate = moment(this.state.item.dueDate).format('YYYY-MM-DD HH:mm:ss')
        console.log(formatedDate)
        this.setState({
            item: {
                ...this.state.item,
                dueDate: date
            }
        })
        
    }

    uploadHandler = () => {
        console.log('Upload Handler');
        console.log(this.state.selectedFile);
        const formData = new FormData();
        formData.append("adId", String(31));
        for (let file of this.state.selectedFile) {
            formData.append("files[]", file);
        }
        axios.post('http://18.219.17.99:3000/ads/upload-ad-image', formData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((response) => {
                console.log("Image Upload Response")
                console.log(response.data);

            });
    }


    render() {
        return (
            <div className="AddItem">
                <h1>ADD Task</h1>
                <form>
                    <fieldset>
                        <fieldset className="form-group">
                            <input
                                className="form-control form-control-lg"
                                type="text"
                                placeholder="Task Name"

                                onChange={this.changeTaskName}
                                onBlur={() => { this.validator.showMessageFor('itemName'); this.forceUpdate(); }} />

                            <span className="error">{this.validator.message('itemName', this.state.item.name, 'required')}</span>
                        </fieldset>
                        <fieldset className="form-group">
                            <input
                                className="form-control form-control-lg"
                                type="text"
                                placeholder="Task Description"
                                onChange={this.changeTaskDescription}
                                onBlur={() => { this.validator.showMessageFor('itemDescription'); this.forceUpdate(); }} />
                            <span className="error">{this.validator.message('itemDescription', this.state.item.description, 'required')}</span>
                        </fieldset>
                        <fieldset className="form-group">
                            <DatePicker
                                selected={this.state.item.dueDate}
                                minDate={new Date()}
                                onSelect={this.handleDateSelect} //when day is clicked
                                onChange={this.handleDateChange} //only when value has changed
                            />

                        </fieldset>
                        <fieldset className="form-group">
                            <input type="file" onChange={this.fileChangedHandler}></input>
                        </fieldset>
                        <button
                            className="btn btn-lg btn-primary pull-xs-right"
                            type="button" onClick={this.addItem}>
                            Add Item
                        </button>
                    </fieldset>
                </form>
            </div>


        );

    }
}

export default AddTask;