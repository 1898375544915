import React, { useState, useEffect } from 'react';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import { connect } from 'react-redux'
import _ from 'lodash'
import { fetchData } from './TaskSubmissionOverview.logic'

import Modal from '../components/Modal'
import Loader from '../components/Loader'
import './TaskSubmissionOverview.css'


const TaskSubmissionOverview = (props) => {

    const [showModal, setShowModal] = useState(false)
    const [selectedResult, setSelectedResult] = useState()
    const [task, setTask] = useState()

    const toggleModal = (task, result) => {
        if (result !== undefined) {
            setSelectedResult(prevState => result)
            setTask(prevState => task)
            setShowModal(prevState => !prevState)
        }
    }

    const filterTask = (taskId, tasks) => {
        return _.find(tasks, ['task_id', taskId])
    }

    const getTaskStatus = (taskId, tasks) => {

        const task = filterTask(taskId, tasks)

        if (task === undefined)
            return <BsXCircle className="submissionNo icon-image" />

        return task.accepted === 0 ? <BsCheckCircle className="not-graded icon-image" /> : <BsCheckCircle className="submissionYes icon-image" />

    }

    useEffect(() => {
        if (props.dataSaved)
            props.fetchData()
    }, [props.dataSaved])

    useEffect(() => {
        props.fetchData()
    }, [])

    return (
        <div className="body-container">
            <div className="scroll-view">
                {
                    props.dataFetched &&
                    <table>
                        <thead>
                            <tr>
                                <td>
                                    <div className="tableData">
                                        <div className="symbol">
                                            <BsXCircle className="submissionNo icon-image" />
                                    Not submitted
                                </div>
                                        <div className="symbol">
                                            <BsCheckCircle className="not-graded icon-image" />
                                    Submitted but not graded
                                </div>
                                        <div className="symbol">
                                            <BsCheckCircle className="submissionYes icon-image" />
                                    Graded
                                </div>
                                    </div>
                                </td>
                                {props.tasks && props.tasks.length > 0 &&
                                    props.tasks.map(task =>
                                        <td key={task.id}>
                                            <div className="task-item">
                                                <span className="testtext">
                                                    {task.id}. {task.title}
                                                </span>
                                                {/* <span className="testtext">
                                                    {task.id}
                                                </span> */}
                                            </div>
                                        </td>
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {props.submittedTasks && props.submittedTasks.length > 0 &&
                                props.submittedTasks.map(student =>
                                    <tr key={student.uid}>
                                        <td>{student.name}</td>
                                        {
                                            props.tasks.map(task =>
                                                <td key={task.id} onClick={() => toggleModal(task, filterTask(task.id, student.tasks))}>
                                                    {getTaskStatus(task.id, student.tasks)}
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                }
            </div>
            {
                !props.dataFetched &&
                <Loader />
            }
            {
                showModal &&
                <Modal setShowModal={toggleModal}
                    selectedResult={selectedResult}
                    task={task} />
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.submissionOverview.isLoading,
        tasks: state.submissionOverview.tasks,
        submittedTasks: state.submissionOverview.submittedTasks,
        dataFetched: state.submissionOverview.dataFetched,
        dataSaved: state.saveGrade.dataSaved
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: () => dispatch(fetchData())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TaskSubmissionOverview)
